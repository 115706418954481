import React, { useState, useEffect } from 'react';
import {
	BrowserRouter as Router,
	Link,
	useLocation
  } from "react-router-dom";

import AuthService from "../services/auth.service"
import {getRandomColor,createImageFromInitials} from '../components/Utils'
import { gql, useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import LoadingComponent from "./loading.component";
import 'moment/locale/es';
import Moment from 'moment';
import io from "socket.io-client";


export const GET_ASIGNACIONACTOS = gql`
query ($usuarioId: String) {
    viewer {
      allAsignacionTareas(filters: {estatusNe: "T", ejecutor: $usuarioId}) {
        edges {
          node {
            id
            codigo
            actividad
            comentarios {
              edges {
                node {
                  id
                  comentario
                  comento
                  comentarioPor {
                    id
                    nombre
                    paterno
                    materno
                  }
                  createdAt
                  updatedAt
                }
              }
            }
            actividadAsignado {
              id
              codigo
              actividadAsignado {
                id
                codigo
                nombre
              }
            }
            ejecutor
            tarea
            tareaAsignada {
              id
              codigo
              nombre
            }
            asigno
            asignadoPor {
              id
              username
              nombre
              paterno
              materno
            }
            ejecutorAsignado {
              id
              username
              nombre
              paterno
              materno
            }
            fechaAsignacion
            fechaEstimada
            frecuencia
            estatus
            grado
            color
            tipo
            createdAt
          }
        }
      }
    }
  }
`;

const NotificationsComponent = () => {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;
    const [socket, setSocket] = useState(null);
    const ENDPOINT = "https://api.vittapp.com";
    //const [state, setState] = useState(items);

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    const [showModeratorBoard, setAsesorBoard] = useState(false);
    const [showAdminBoard, setAdminBoard] = useState(false);
    const [showNotariaBoard, setNotariaBoard] = useState(false);
    const [toggleStatus, setToggleSideBar] = useState(null);
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const [totalNotifications, setTotalNotifications] = useState(0);
    const [showNotifications, setShowNotifications] = useState('None');
    var notification;

    const { loading, data : dataTareas, error, refetch} = useQuery(GET_ASIGNACIONACTOS,
        {
          variables: {usuarioId : currentUser.username}
        }, 
        {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted(data){
            const notifications = data.viewer.allAsignacionTareas.edges.length;
            setTotalNotifications(notifications);
        },    
        onError(error){
          //reset();
          const id = toast.loading("Por favor espere...")
          toast.update(id, { render: `Error! ${error.message}`,
          type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
          autoClose: 5000,  isLoading: false, closeOnClick: true });    
        }
      });

      function  showNotification() {
        var options = {    
          body: "Tienes tareas pendientes",    
          icon: "https://whip.creasis.mx/app-assets/images/logo/materialize-logo-color.png",
        };    
       notification = new Notification("Whip App", options);
    
    }


    function toggleSideBar(){
		var element = document.getElementById("sidebar");
        //element.classList.toggle("nav-collapsed");
        element.classList.toggle("nav-lock");
    }

    let color;

    function getColor (estatus)  {
  const today = new Date();
  const fecha = new Date(estatus.fechaEstimada);

  if(fecha.getTime() < today.getTime()){
    color = 'red';
    return color;
  }
  switch (estatus.estatus) {
    case 'P':
      return color = 'green';
    case 'E':
      return  color = 'orange';
    case 'T':
      return  color = 'cyan';
    case 'C':
        return  color = 'brown';
    default:
        return null;
  }
  
}



    useEffect(() => {
  const socket = io(ENDPOINT);
    setSocket(socket);
    
    socket.on('data-tmp', (operators) => {
      refetch();
      //setTotalNotifications(data.viewer.allAsignacionTareas.edges.length);
      toast.dismiss();
      const id = toast.loading("Por favor espere...")        
      toast.update(id, { render: `Hubo una actualizacion de tarea`,
      type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
      //this.temperature = data.temperature + '°C';
      //this.humidity = data.humedity;
      //this.color = this.getTempColor(data.temperature);
        const notifications = dataTareas.viewer.allAsignacionTareas.edges.length;
        setTotalNotifications(notifications);
        if(notifications > 0){
          setShowNotifications('');
          showNotification();
        }else{
          setShowNotifications('None');
        }
      });
      if(dataTareas){
        const notifications = dataTareas.viewer.allAsignacionTareas.edges.length;
        setTotalNotifications(notifications);
        if(notifications > 0){
          setShowNotifications('');
          
        }else{
          setShowNotifications('None');
          
        }
      }
  
    return () => socket.disconnect();
}, [refetch, dataTareas])

    //if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;


    return (
       
           
        <li>
            
        <a className="waves-block waves-light notification-button" href="javascript:void(0);" data-target="notifications-dropdown">
            <i className="material-icons">notifications_none
            <small className="notification-badge" style={{display : showNotifications}}>
                {totalNotifications}</small></i>
        </a>
        <ul className="dropdown-content" id="notifications-dropdown" tabindex="0">
        <>
        <li tabindex="0"  style={{display: showNotifications}}>
          <h6>NOTIFICACIONES<span className="new badge">{totalNotifications}</span></h6>
        </li>
        </>
        <li className="divider" tabindex="0"></li>

        {loading ? 
                                                <LoadingComponent /> 
                                                :
        
        dataTareas.viewer.allAsignacionTareas.edges.map(
                                                        record => 

        <li tabindex="0" key = {record.node.id}>
            <Link className="black-text" to={`/procesos/asignaciontareas/${record.node.id}`}>
            <span className={`material-icons icon-bg-circle ${getColor(record.node)} small`}>assignment</span>
            {record.node.tareaAsignada.codigo} {record.node.tareaAsignada.nombre}</Link>
          <time className="media-meta grey-text darken-2" 
          datetime="2015-06-12T20:50:48+08:00">{Moment(record.node.fechaEstimada).fromNow(new Date(record.node.fechaEstimada) > new Date() ? true : false).toUpperCase()}, {Moment(record.node.fechaEstimada).format('dddd, D [de] MMMM [de] YYYY').toUpperCase()}</time>
        </li>
        )
        }

{error ? <div className="card-alert card red lighten-5">
                                                          <div className="card-content red-text">
                                                            <p>{`Error! ${error.message}`}</p>
                                                          </div>
                                                          <button type="button" className="close red-text" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                          </button>
                                                        </div> : null}
      </ul>
      </li>
        
        );
    }

export default NotificationsComponent;
