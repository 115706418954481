import React, { Component } from "react";

export default class LoadingComponent extends Component {
  
  render() {
    return (
        <div className="progress">
            <div className="indeterminate"></div>
        </div>
    );
  }
}
