import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Link, 
	} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "./App.css";
import AuthService from "./services/auth.service";
import { gql} from '@apollo/client';
import {getRandomColor,createImageFromInitials} from './components/Utils'

import LangSelector from "./components/LangSelector";
import ImportComponent from "./components/imports.component";

import EventBus from "./common/EventBus";
import GuardedRoute from './components/GuardedRoute';
import AsideBarComponent from './components/AsideBarComponent';
import NotificationsComponent from './components/NotificationsComponent';

import HeaderComponent from './components/HeaderComponent';
import RightSideBarNavComponent from './components/RightSideBarNavComponent';
import ThemeCutomizerSideNavComponent from './components/ThemeCutomizerSideNavComponent';
import FooterComponent from './components/FooterComponent';
import Loadable from './components/Loadable';

const Login = Loadable(lazy(() => import('./components/login.component')));

//import Login from "./components/login.component";
//const NavBarComponent = Loadable(lazy(() => import('./components/NavBarComponent')));
const Register = Loadable(lazy(() => import('./components/auth/register.component')));
const EmailVerification = Loadable(lazy(() => import('./components/auth/email-verification.component')));
const Home = Loadable(lazy(() => import('./components/home.component')));
const Profile = Loadable(lazy(() => import('./components/profile.component')));
const BoardUser = Loadable(lazy(() => import('./components/board-user.component')));
const BoardModerator = Loadable(lazy(() => import('./components/board-moderator.component')));
const BoardAdmin = Loadable(lazy(() => import('./components/board-admin.component')));
const UsuariosPerfilComponent  = Loadable(lazy(() => import('./components/catalogos/usuarios/usuarios-perfil.component')));
const UsuariosIndexComponent = Loadable(lazy(() => import('./components/catalogos/usuarios/usuarios-index.component'))); 
const UsuariosCreateComponent  = Loadable(lazy(() => import('./components/catalogos/usuarios/usuarios-create.component')));
const UsuariosEditComponent  = Loadable(lazy(() => import('./components/catalogos/usuarios/usuarios-edit.component')));

// const AuthVerify  "./common/auth-verify"
const RequisitosIndexComponent  = Loadable(lazy(() => import("./components/catalogos/requisitos/requisitos-index.component")));
const RequisitosEditComponent  = Loadable(lazy(() => import("./components/catalogos/requisitos/requisitos-edit.component")));
const RequisitosCreateComponent = Loadable(lazy(() => import("./components/catalogos/requisitos/requisitos-create.component"))); 

const RolesIndexComponent = Loadable(lazy(() => import("./components/catalogos/roles/roles-index.component"))); 

const ActosIndexComponent = Loadable(lazy(() => import("./components/catalogos/actos/actos-index.component"))); 
const ActosCreateComponent  = Loadable(lazy(() => import("./components/catalogos/actos/actos-create.component")));
const ActosEditComponent  = Loadable(lazy(() => import("./components/catalogos/actos/actos-edit.component")));

const ActividadesIndexComponent  = Loadable(lazy(() => import("./components/catalogos/actividades/actividades-index.component")));
const ActividadesCreateComponent = Loadable(lazy(() => import("./components/catalogos/actividades/actividades-create.component"))); 
const ActividadesEditComponent  = Loadable(lazy(() => import("./components/catalogos/actividades/actividades-edit.component")));

const EtapasIndexComponent  = Loadable(lazy(() => import("./components/catalogos/etapas/etapas-index.component")));
const EtapasCreateComponent = Loadable(lazy(() => import("./components/catalogos/etapas/etapas-create.component"))); 
const EtapasEditComponent  = Loadable(lazy(() => import("./components/catalogos/etapas/etapas-edit.component")));

const TareasIndexComponent  = Loadable(lazy(() => import("./components/catalogos/tareas/tareas-index.component")));
const TareasCreateComponent  = Loadable(lazy(() => import("./components/catalogos/tareas/tareas-create.component")));
const TareasEditComponent = Loadable(lazy(() => import("./components/catalogos/tareas/tareas-edit.component"))); 

const ComentariosIndexComponent  = Loadable(lazy(() => import("./components/catalogos/comentarios/comentarios-index.component")));
const ComentariosCreateComponent = Loadable(lazy(() => import("./components/catalogos/comentarios/comentarios-create.component"))); 
const ComentariosEditComponent = Loadable(lazy(() => import("./components/catalogos/comentarios/comentarios-edit.component"))); 

const ContactosIndexComponent = Loadable(lazy(() => import("./components/catalogos/contactos/contactos-index.component"))); 
const ContactosCreateComponent  = Loadable(lazy(() => import("./components/catalogos/contactos/contactos-create.component")));
const ContactosEditComponent = Loadable(lazy(() => import("./components/catalogos/contactos/contactos-edit.component"))); 

const ResidentesIndexComponent  = Loadable(lazy(() => import("./components/catalogos/residentes/residentes-index.component")));
const ResidentesCreateComponent = Loadable(lazy(() => import("./components/catalogos/residentes/residentes-create.component")));  
const ResidentesEditComponent = Loadable(lazy(() => import("./components/catalogos/residentes/residentes-edit.component"))); 


const SubDesarrollosIndexComponent = Loadable(lazy(() => import("./components/catalogos/subdesarrollo/subdesarrollo-index.component"))); 
const SubDesarrollosCreateComponent  = Loadable(lazy(() => import("./components/catalogos/subdesarrollo/subdesarrollo-create.component")));
const SubDesarrollosEditComponent = Loadable(lazy(() => import("./components/catalogos/subdesarrollo/subdesarrollo-edit.component"))); 

const DesarrollosIndexComponent = Loadable(lazy(() => import("./components/catalogos/desarrollo/desarrollo-index.component"))); 
const DesarrollosCreateComponent = Loadable(lazy(() => import("./components/catalogos/desarrollo/desarrollo-create.component"))); 
const DesarrollosEditComponent = Loadable(lazy(() => import("./components/catalogos/desarrollo/desarrollo-edit.component"))); 
const UploadCreateComponent = Loadable(lazy(() => import("./components/catalogos/upload/upload-create.component"))); 

const ProTareasIndexComponent = Loadable(lazy(() => import("./components/procesos/tareas/pro-tareas-index.component"))); 
const AsignacionTareasEditComponent = Loadable(lazy(() => import("./components/procesos/tareas/pro-tareas-edit.component"))); 
const AsignacionTareasCreateComponent = Loadable(lazy(() => import("./components/procesos/tareas/pro-tareas-create.component"))); 

const ProActosIndexComponent = Loadable(lazy(() => import("./components/procesos/actos/pro-actos-index.component"))); 
const AsignacionActosEditComponent = Loadable(lazy(() => import("./components/procesos/actos/pro-actos-edit.component"))); 
const AsignacionActosCreateComponent = Loadable(lazy(() => import("./components/procesos/actos/pro-actos-create.component"))); 

const ProActividadesIndexComponent = Loadable(lazy(() => import("./components/procesos/actividades/pro-actividades-index.component"))); 
const AsignacionActividadesEditComponent = Loadable(lazy(() => import("./components/procesos/actividades/pro-actividades-edit.component"))); 
const AsignacionActividadesCreateComponent = Loadable(lazy(() => import("./components/procesos/actividades/pro-actividades-create.component"))); 


const DashIndexComponent = Loadable(lazy(() => import("./components/tareas/dash-index.component"))); 
const DashIndexComponentWorking = Loadable(lazy(() => import("./components/tareas/dash-index-working.component"))); 

const DashAdminComponent = Loadable(lazy(() => import("./components/tareas/dash-admin.component"))); 


//const GuardedRoute = Loadable(lazy(() => import('./components/GuardedRoute'))); 
//const AsideBarComponent = Loadable(lazy(() => import('./components/AsideBarComponent'))); 
//const NotificationsComponent = Loadable(lazy(() => import('./components/NotificationsComponent'))); 

//const HeaderComponent = Loadable(lazy(() => import('./components/HeaderComponent'))); 
//const RightSideBarNavComponent = Loadable(lazy(() => import('./components/RightSideBarNavComponent'))); 
//const ThemeCutomizerSideNavComponent = Loadable(lazy(() => import('./components/ThemeCutomizerSideNavComponent'))); 
//const FooterComponent = Loadable(lazy(() => import('./components/FooterComponent'))); 

const MovieList = Loadable(lazy(() => import("./components/reportes/usuarios/index.componet"))); 
const UsuariosPDFDinamico = Loadable(lazy(() => import("./components/reportes/usuarios/dinamico.componet"))); 
const ReporteTareasEjecutor = Loadable(lazy(() => import("./components/reportes/tareas/index.componet"))); 
const ReporteActosVencidos = Loadable(lazy(() => import("./components/reportes/actos/index.componet"))); 
const ReporteActividadesVencidas = Loadable(lazy(() => import("./components/reportes/actividades/index.componet"))); 

const DepartamentosIndexComponent = Loadable(lazy(() => import("./components/catalogos/departamentos/departamentos-index.component"))); 
const DepartamentosCreateComponent = Loadable(lazy(() => import("./components/catalogos/departamentos/departamentos-create.component"))); 
const DepartamentosEditComponent = Loadable(lazy(() => import("./components/catalogos/departamentos/departamentos-edit.component"))); 

const TorresIndexComponent = Loadable(lazy(() => import("./components/catalogos/torres/torres-index.component"))); 
const TorresCreateComponent  = Loadable(lazy(() => import("./components/catalogos/torres/torres-create.component")));
const TorresEditComponent = Loadable(lazy(() => import("./components/catalogos/torres/torres-edit.component"))); 

const VehiculosIndexComponent  = Loadable(lazy(() => import("./components/catalogos/vehiculos/index.component")));
const VehiculosCreateComponent = Loadable(lazy(() => import("./components/catalogos/vehiculos/create.component"))); 
const VehiculosEditComponent = Loadable(lazy(() => import("./components/catalogos/vehiculos/edit.component"))); 

const CategoriasBlogsIndexComponent = Loadable(lazy(() => import("./components/catalogos/categoriasblogs/categoriasblogs-index.component"))); 
const CategoriasBlogsCreateComponent = Loadable(lazy(() => import("./components/catalogos/categoriasblogs/categoriasblogs-create.component"))); 
const CategoriasBlogsEditComponent = Loadable(lazy(() => import("./components/catalogos/categoriasblogs/categoriasblogs-edit.component"))); 

const BlogDescripcionIndexComponent = Loadable(lazy(() => import("./components/catalogos/blogdescripcion/blogdescripcion-index.component"))); 
const BlogDescripcionCreateComponent = Loadable(lazy(() => import("./components/catalogos/blogdescripcion/blogdescripcion-create.component"))); 
const BlogDescripcionEditComponent = Loadable(lazy(() => import("./components/catalogos/blogdescripcion/blogdescripcion-edit.component"))); 

const HabitacionesIndexComponent = Loadable(lazy(() => import("./components/catalogos/habitaciones/habitaciones-index.component"))); 
const HabitacionesCreateComponent = Loadable(lazy(() => import("./components/catalogos/habitaciones/habitaciones-create.component"))); 
const HabitacionesEditComponent = Loadable(lazy(() => import("./components/catalogos/habitaciones/habitaciones-edit.component"))); 

const AreasComunesCreateComponent  = Loadable(lazy(() => import("./components/catalogos/areascomunes/areascomunes-create.component"))); 
const AreasComunesEditComponent = Loadable(lazy(() => import("./components/catalogos/areascomunes/areascomunes-edit.component"))); 


const InventarioAreasIndexComponent = Loadable(lazy(() => import("./components/catalogos/inventarioareas/inventarioareas-index.component"))); 
const InventarioAreasCreateComponent = Loadable(lazy(() => import("./components/catalogos/inventarioareas/inventarioareas-create.component"))); 
const InventarioAreasEditComponent = Loadable(lazy(() => import("./components/catalogos/inventarioareas/inventarioareas-edit.component"))); 

const BitacoraVisitantesIndexComponent = Loadable(lazy(() => import("./components/catalogos/bitacoravisitantes/bitacoravisitantes-index.component"))); 
const BitacoraVisitantesCreateComponent = Loadable(lazy(() => import("./components/catalogos/bitacoravisitantes/bitacoravisitantes-create.component"))); 
const BitacoraVisitantesEditComponent = Loadable(lazy(() => import("./components/catalogos/bitacoravisitantes/bitacoravisitantes-edit.component"))); 

const BitacoraIncidenciasIndexComponent = Loadable(lazy(() => import("./components/catalogos/bitacoraincidencias/bitacoraincidencias-index.component"))); 
const BitacoraIncidenciasCreateComponent = Loadable(lazy(() => import("./components/catalogos/bitacoraincidencias/bitacoraincidencias-create.component"))); 
const BitacoraIncidenciasEditComponent = Loadable(lazy(() => import("./components/catalogos/bitacoraincidencias/bitacoraincidencias-edit.component"))); 

const ProfileCondominioComponent = Loadable(lazy(() => import("./components/condominios/modulos/profile-condominio.component"))); 
const BlogsCondominioComponent = Loadable(lazy(() => import("./components/condominios/modulos/blogs-condominio.component"))); 
const BlogsListCondominioComponent = Loadable(lazy(() => import("./components/condominios/modulos/blogs-list-condominio.component"))); 

const DirectorioCategoriasCondominioComponent = Loadable(lazy(() => import("./components/condominios/modulos/directorio-categorias-condominio.component")));  



const ProveedoresIndexComponent = Loadable(lazy(() => import('./components/catalogos/proveedores/index.component')));
const ProveedoresCreateComponent = Loadable(lazy(() => import('./components/catalogos/proveedores/create.component')));
const ProveedoresEditComponent = Loadable(lazy(() => import('./components/catalogos/proveedores/edit.component')));
const ProveedoresImportComponent = Loadable(lazy(() => import('./components/catalogos/proveedores/import.component')));

const TelefonosIndexComponent = Loadable(lazy(() => import('./components/catalogos/telefonosemergencia/index.component')));
const TelefonosCreateComponent = Loadable(lazy(() => import('./components/catalogos/telefonosemergencia/create.component')));
const TelefonosEditComponent = Loadable(lazy(() => import('./components/catalogos/telefonosemergencia/edit.component')));
const TelefonosImportComponent = Loadable(lazy(() => import('./components/catalogos/telefonosemergencia/import.component')));
const AreasComunesIndexComponent = Loadable(lazy(() => import('./components/catalogos/areascomunes/areascomunes-index.component')));
const CoversacionesComponent = Loadable(lazy(() => import('./components/condominios/modulos/conversaciones.component')));

const IngresosCreateComponent = Loadable(lazy(() => import('./components/procesos/finanzas/create.component')));
const AjustesHabitacionesIndexComponent = Loadable(lazy(() => import('./components/ajustes/unidades/index.component')));
const ConfiguracionCuotasComponent = Loadable(lazy(() => import('./components/finanzas/cuotas/configuracion.component')));

const ConfiguracionIngresosComponent = Loadable(lazy(() => import('./components/finanzas/ingresos/configuracion.component')));

const WorkingPage = Loadable(lazy(() => import('./components/working.componet')));


const TiposViviendaIndexComponent = Loadable(lazy(() => import('./components/catalogos/tiposvivienda/tiposvivienda-index.component')));
const TiposViviendaCreateComponent = Loadable(lazy(() => import('./components/catalogos/tiposvivienda/tiposvivienda-create.component')));
const TiposViviendaEditComponent = Loadable(lazy(() => import('./components/catalogos/tiposvivienda/tiposvivienda-edit.component')));

const EgresosComponent = Loadable(lazy(() => import('./components/finanzas/egresos/egresos.component')));
const UnidadesComponent = Loadable(lazy(() => import('./components/catalogos/habitaciones/configuracion.component')));

const TicketsIndexComponent = Loadable(lazy(() => import('./components/catalogos/tickets/tickets-index.component')));

const ReporteIngresosComponent = Loadable(lazy(() => import('./components/reportes/ingresos/index.component')));



const GET_USUARIO = gql`
query usuario($usuarioId: String) {
  viewer {
    usuario(usuarioId: $usuarioId) {
      id
          nombre
          paterno
          materno
          email
          usuario
          estatus
          sexo
          createdAt
          condominioId
      		condominio{
            id
            empresa
          }
          roles{
            edges{
              node{
                id
                name
              }
            }
          }
          categorias{
            edges{
              node{
                id
                nombre
              }
            }
          }
    }
  }
}
`




class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    

    //if (user) {
    if (user) {
      document.body.classList.remove('login-bg');
      this.setState({
        currentUser: user,
        //showModeratorBoard: user.roles.includes("ROLE_ASESOR"),
        //showAdminBoard: user.roles.includes("ROLE_ADMINISTRADOR"),
        showModeratorBoard: true,
        showAdminBoard: true,
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });

    if (!("Notification" in window)) {

      console.log("This browser does not support desktop notification");

    } else {

      Notification.requestPermission();

    }
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }


  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (

                          
                      
      
      <div>
          { (currentUser) ?
          <>
    <header className="page-topbar" id="header">
      <div className="navbar navbar-fixed"> 
        <nav className="navbar-main navbar-color nav-collapsible sideNav-lock no-shadow cyan">
          <div className="nav-wrapper">
            <div className="header-search-wrapper hide-on-med-and-down">
                
            </div>
            <ul className="navbar-list right">
                    <LangSelector />
                    {/*<NotificationsComponent />*/}
                      
              
              <li>
                  <a className="profile-button" href="#" data-target="profile-dropdown"><span className="avatar-status avatar-online">
                    <img src={createImageFromInitials(100, `${currentUser.username}`, getRandomColor())
                                        } alt="avatar"/>
                    <i></i></span>
                  </a></li>
              
            </ul>
            
            <ul className="dropdown-content" id="profile-dropdown">
              <li>
                <Link className="grey-text text-darken-1" to={"/ajustes/ajustes/ajustes-cuenta"}>
                  <i className="material-icons">business</i> Condiminio
                </Link>
              </li>
              <li>
                <Link className="grey-text text-darken-1" to={"/usuario/perfil"}>
                  <i className="material-icons">person_outline</i> Perfil
                </Link>
              </li>
              
              <li>
                <a className="grey-text text-darken-1" onClick={this.logOut} href="/login">
                  <i className="material-icons">keyboard_tab</i> Salir
                </a>
              </li>

            </ul>
          </div>
          <nav className="display-none search-sm">
            <div className="nav-wrapper">
              <form id="navbarForm">
                <div className="input-field search-input-sm">
                  <input className="search-box-sm mb-0" type="search" required="" id="search" placeholder="Explore Materialize" data-search="template-list"/>
                  <label className="label-icon" htmlFor="search"><i className="material-icons search-sm-icon">search</i></label><i className="material-icons search-sm-close">close</i>
                  <ul className="search-list collection search-list-sm display-none"></ul>
                </div>
              </form>
            </div>
          </nav>
        </nav>
      </div>
    </header>

    <ul className="display-none" id="default-search-main">
      <li className="auto-suggestion-title"><a className="collection-item" href="#">
          <h6 className="search-title">FILES</h6></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="/app-assets/images/icon/pdf-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Two new item submitted</span><small className="grey-text">Marketing Manager</small></div>
            </div>
            <div className="status"><small className="grey-text">17kb</small></div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="/app-assets/images/icon/doc-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">52 Doc file Generator</span><small className="grey-text">FontEnd Developer</small></div>
            </div>
            <div className="status"><small className="grey-text">550kb</small></div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="/app-assets/images/icon/xls-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">25 Xls File Uploaded</span><small className="grey-text">Digital Marketing Manager</small></div>
            </div>
            <div className="status"><small className="grey-text">20kb</small></div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="/app-assets/images/icon/jpg-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Anna Strong</span><small className="grey-text">Web Designer</small></div>
            </div>
            <div className="status"><small className="grey-text">37kb</small></div>
          </div></a></li>
      <li className="auto-suggestion-title"><a className="collection-item" href="#">
          <h6 className="search-title">MEMBERS</h6></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar">
                <img className="circle" src={createImageFromInitials(100, `${currentUser.username}`, getRandomColor())
                                        } width="30" alt="sample image"/>
              </div>
              <div className="member-info display-flex flex-column"><span className="black-text">John Doe</span><small className="grey-text">UI designer</small></div>
            </div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img className="circle" src="/app-assets/images/avatar/avatar-8.png" width="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Michal Clark</span><small className="grey-text">FontEnd Developer</small></div>
            </div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img className="circle" src="/app-assets/images/avatar/avatar-10.png" width="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Milena Gibson</span><small className="grey-text">Digital Marketing</small></div>
            </div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img className="circle" src="/app-assets/images/avatar/avatar-12.png" width="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Anna Strong</span><small className="grey-text">Web Designer</small></div>
            </div>
          </div></a></li>
    </ul>
    <ul className="display-none" id="page-search-title">
      <li className="auto-suggestion-title"><a className="collection-item" href="#">
          <h6 className="search-title">PAGES</h6></a></li>
    </ul>
    <ul className="display-none" id="search-not-found">
      <li className="auto-suggestion"><a className="collection-item display-flex align-items-center" href="#"><span className="material-icons">error_outline</span><span className="member-info">No results found.</span></a></li>
    </ul>
    </>
    : null
}


    { (currentUser) ? 
    <>
    <AsideBarComponent />
    </>
        : null
    }
        
        
        {/*Main Div*/}
        <div id={ (currentUser) ?  "main" : null} className="main-full">


        <div className="row">
        
            <div className="col s12">
                <div className="container">
                <ToastContainer autoClose={3000}/>
                    
                    
                    
                    
                                              <Switch>
                                                <GuardedRoute exact path={["/", "/home"]} component={DashIndexComponent} />
                                                <GuardedRoute exact path={["/home-working"]} component={DashIndexComponentWorking} />

                                                <GuardedRoute exact path={["/", "/admin-dash"]} component={DashAdminComponent} />
                                                <Route exact path="/login" component={Login} />
                                                <Route exact path="/authorize" component={Login} />
                                                <Route exact path="/register" component={Register} />
                                                <Route exact path="/email-verificacion/:code" component={EmailVerification} />
                                                <GuardedRoute exact path="/profile" component={Profile} />
                                                <GuardedRoute path="/user" component={BoardUser} />
                                                <GuardedRoute path="/mod" component={BoardModerator} />
                                                <GuardedRoute path="/admin" component={BoardAdmin} />
                                                <GuardedRoute exact path="/usuario/perfil" component={UsuariosPerfilComponent} />
                                                <GuardedRoute exact path="/catalogos/usuarios" component={UsuariosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/usuarios/:id" component={UsuariosEditComponent} />
                                                <GuardedRoute exact path="/catalogos/usuarios/crear/nuevo" component={UsuariosCreateComponent} />

                                                <GuardedRoute exact path="/upload" component={UploadCreateComponent} />


                                                <GuardedRoute exact path="/catalogos/roles" component={RolesIndexComponent} />

                                                <GuardedRoute exact path="/catalogos/actividades" component={ActividadesIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/actividades/crear/nuevo" component={ActividadesCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/actividades/:id" component={ActividadesEditComponent} />

                                                <GuardedRoute exact path="/catalogos/etapas" component={EtapasIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/etapas/crear/nuevo" component={EtapasCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/etapas/:id" component={EtapasEditComponent} />

                                                <GuardedRoute exact path="/catalogos/tareas" component={TareasIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/tareas/crear/nuevo" component={TareasCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/tareas/:id" component={TareasEditComponent} />

                                                <GuardedRoute exact path="/catalogos/departamentos" component={DepartamentosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/departamentos/crear/nuevo" component={DepartamentosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/departamentos/:id" component={DepartamentosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/subdesarrollos" component={SubDesarrollosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/subdesarrollos/crear/nuevo" component={SubDesarrollosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/subdesarrollos/:id" component={SubDesarrollosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/desarrollos" component={DesarrollosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/desarrollos/crear/nuevo" component={DesarrollosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/desarrollos/:id" component={DesarrollosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/contactos" component={ContactosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/contactos/crear/nuevo" component={ContactosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/contactos/:id" component={ContactosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/residentes" component={ResidentesIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/residentes/crear/nuevo" component={ResidentesCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/residentes/:id" component={ResidentesEditComponent} />
    
                                                <GuardedRoute exact path="/catalogos/vehiculos" component={VehiculosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/vehiculos/crear/nuevo" component={VehiculosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/vehiculos/:id" component={VehiculosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/torres" component={TorresIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/torres/crear/nuevo" component={TorresCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/torres/:id" component={TorresEditComponent} />

                                                <GuardedRoute exact path="/catalogos/unidades" component={HabitacionesIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/unidades/crear/nuevo" component={HabitacionesCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/unidades/:id" component={HabitacionesEditComponent} />
                                                <GuardedRoute exact path="/ajustes/unidades" component={AjustesHabitacionesIndexComponent} />

                                                <GuardedRoute exact path="/catalogos/areascomunes" component={AreasComunesIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/areascomunes/crear/nuevo" component={AreasComunesCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/areascomunes/:id" component={AreasComunesEditComponent} />

                                                <GuardedRoute exact path="/catalogos/inventarioareas" component={InventarioAreasIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/inventarioareas/crear/nuevo" component={InventarioAreasCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/inventarioareas/:id" component={InventarioAreasEditComponent} />

                                                <GuardedRoute exact path="/catalogos/bitacoravisitantes" component={BitacoraVisitantesIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/bitacoravisitantes/crear/nuevo" component={BitacoraVisitantesCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/bitacoravisitantes/:id" component={BitacoraVisitantesEditComponent} />

                                                <GuardedRoute exact path="/catalogos/bitacoraincidencias" component={BitacoraIncidenciasIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/bitacoraincidencias/crear/nuevo" component={BitacoraIncidenciasCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/bitacoraincidencias/:id" component={BitacoraIncidenciasEditComponent} />

                                                <GuardedRoute exact path="/catalogos/categoriasblogs" component={CategoriasBlogsIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/categoriasblogs/crear/nuevo" component={CategoriasBlogsCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/categoriasblogs/:id" component={CategoriasBlogsEditComponent} />

                                                <GuardedRoute exact path="/catalogos/blogdescripcion" component={BlogDescripcionIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/blogdescripcion/crear/nuevo" component={BlogDescripcionCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/blogdescripcion/:id" component={BlogDescripcionEditComponent} />

                                                <GuardedRoute exact path="/catalogos/comentarios" component={ComentariosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/comentarios/crear/nuevo" component={ComentariosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/comentarios/:id" component={ComentariosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/requisitos" component={RequisitosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/requisitos/:id" component={RequisitosEditComponent} />
                                                <GuardedRoute exact path="/catalogos/requisitos/crear/nuevo" component={RequisitosCreateComponent} />
                                                
                                                <GuardedRoute exact path="/catalogos/proveedores" component={ProveedoresIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/proveedores/crear/nuevo" component={ProveedoresCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/proveedores/:id" component={ProveedoresEditComponent} />
                                                <GuardedRoute exact path="/catalogos/proveedores/importar/hojadecalculo" component={ProveedoresImportComponent} />

                                                <GuardedRoute exact path="/catalogos/telefonos" component={TelefonosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/telefonos/crear/nuevo" component={TelefonosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/telefonos/:id" component={TelefonosEditComponent} />
                                                <GuardedRoute exact path="/catalogos/telefonos/importar/hojadecalculo" component={TelefonosImportComponent} />

                                                <GuardedRoute exact path="/importar/:modelo" component={ImportComponent} />


                                                <GuardedRoute exact path="/procesos/asignaciontareas" component={ProTareasIndexComponent} />
                                                <GuardedRoute exact path="/procesos/asignaciontareas/:id" component={AsignacionTareasEditComponent} />
                                                <GuardedRoute exact path="/procesos/asignaciontareas/crear/nuevo" component={AsignacionTareasCreateComponent} />


                                                <GuardedRoute exact path="/procesos/asignacionactos" component={ProActosIndexComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactos/:id" component={AsignacionActosEditComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactos/crear/nuevo" component={AsignacionActosCreateComponent} />

                                                <GuardedRoute exact path="/procesos/asignacionactividades" component={ProActividadesIndexComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactividades/:id" component={AsignacionActividadesEditComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactividades/crear/nuevo" component={AsignacionActividadesCreateComponent} />
                                                
                                                <GuardedRoute exact path="/finanzas/ingresos/crear/nuevo" component={IngresosCreateComponent} />
                                                <GuardedRoute exact path="/finanzas/cuotas/configuracion/:current" component={ConfiguracionCuotasComponent} />

                                                <GuardedRoute exact path="/finanzas/ingresos/configuracion/:current" component={ConfiguracionIngresosComponent} />


                                                <GuardedRoute exact path="/home" component={DashIndexComponent} />
                                                <GuardedRoute exact path="/reporte/users" component={MovieList} />
                                                <GuardedRoute exact path="/reportes/users" component={MovieList} />
                                                <GuardedRoute exact path="/dinamico/users" component={UsuariosPDFDinamico} />
                                                <GuardedRoute exact path="/reportes/tareas-ejecutor" component={ReporteTareasEjecutor} />
                                                <GuardedRoute exact path="/reportes/actos-vencidos" component={ReporteActosVencidos} />
                                                <GuardedRoute exact path="/reportes/actividades-vencidas" component={ReporteActividadesVencidas} />
                                                <GuardedRoute exact path="/conversaciones" component={CoversacionesComponent} />
                                                <GuardedRoute exact path="/perfil/condominio" component={ProfileCondominioComponent} />
                                                <GuardedRoute exact path="/blogs" component={BlogsCondominioComponent} />
                                                <GuardedRoute exact path="/blogs/:id" component={BlogsListCondominioComponent} />
                                                <GuardedRoute exact path="/directorios" component={DirectorioCategoriasCondominioComponent} />

                                                <GuardedRoute exact path="/finanzas/cuotas/:current" component={ConfiguracionCuotasComponent} />
                                                <GuardedRoute exact path="/finanzas/ingresos/:current" component={ConfiguracionIngresosComponent} />
                                                
                                                <GuardedRoute exact path="/finanzas/egresos/catalogo-de-proveedores" component={ProveedoresIndexComponent} />
                                                <GuardedRoute exact path="/finanzas/egresos/:current" component={EgresosComponent} />

                                                <GuardedRoute exact path="/finanzas/egresos/catalogo-de-proveedores/crear" component={ProveedoresCreateComponent} />
                                                <GuardedRoute exact path="/finanzas/egresos/catalogo-de-proveedores/:id/editar" component={ProveedoresEditComponent} />

                                                <GuardedRoute exact path="/ajustes/ajustes/ajustes-cuenta" component={ProfileCondominioComponent} />

                                                <GuardedRoute exact path="/administracion/torres/torres" component={TorresIndexComponent} />
                                                <GuardedRoute exact path="/administracion/torres/torres/crear" component={TorresCreateComponent} />
                                                <GuardedRoute exact path="/administracion/torres/torres/:id/editar" component={TorresEditComponent} />

                                                {/*<GuardedRoute exact path="/administracion/unidades/unidades" component={HabitacionesIndexComponent} />*/}
                                                <GuardedRoute exact path="/administracion/unidades/:current" component={UnidadesComponent} />
                                                
                                                <GuardedRoute exact path="/administracion/unidades/unidades/crear" component={HabitacionesCreateComponent} />
                                                <GuardedRoute exact path="/administracion/unidades/unidades/:id/editar" component={HabitacionesEditComponent} />

                                                <GuardedRoute exact path="/administracion/usuarios/directorio" component={ResidentesIndexComponent} />
                                                <GuardedRoute exact path="/administracion/usuarios/directorio/crear" component={ResidentesCreateComponent} />
                                                <GuardedRoute exact path="/administracion/usuarios/directorio/:id/editar" component={ResidentesEditComponent} />

                                                <GuardedRoute exact path="/administracion/areas-comunes/administrar-areas" component={AreasComunesIndexComponent} />
                                                <GuardedRoute exact path="/administracion/areas-comunes/administrar-areas/crear" component={AreasComunesCreateComponent} />
                                                <GuardedRoute exact path="/administracion/areas-comunes/administrar-areas/:id/editar" component={AreasComunesEditComponent} />
                                                <GuardedRoute exact path="/administracion/areas-comunes/administrar-inventario" component={InventarioAreasIndexComponent} />
                                                <GuardedRoute exact path="/administracion/areas-comunes/administrar-inventario/crear" component={InventarioAreasCreateComponent} />
                                                <GuardedRoute exact path="/administracion/areas-comunes/administrar-inventario/:id/editar" component={InventarioAreasEditComponent} />

                                                <GuardedRoute exact path="/administracion/visitas/visitas" component={BitacoraVisitantesIndexComponent} />
                                                <GuardedRoute exact path="/administracion/visitas/visitas/crear" component={BitacoraVisitantesCreateComponent} />
                                                <GuardedRoute exact path="/administracion/visitas/visitas/:id/editar" component={BitacoraVisitantesEditComponent} />
                                                
                                                <GuardedRoute exact path="/administracion/visitas/vehiculos" component={VehiculosIndexComponent} />


                                                <GuardedRoute exact path="/administracion/visitas/bitacora" component={BitacoraVisitantesIndexComponent} />
                                                {/*<GuardedRoute exact path="/administracion/tickets/gestionar-tickets" component={BitacoraIncidenciasIndexComponent} />*/}
                                                <GuardedRoute exact path="/administracion/tickets/gestionar-tickets" component={TicketsIndexComponent} />
                                                <GuardedRoute exact path="/administracion/tickets/gestionar-tickets/crear" component={BitacoraIncidenciasCreateComponent} />
                                                <GuardedRoute exact path="/administracion/tickets/gestionar-tickets/:id/editar" component={BitacoraIncidenciasEditComponent} />

                                                <GuardedRoute exact path="/administracion/tickets/reporte-tickets" component={BitacoraIncidenciasIndexComponent} />
                                                
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/anuncios-generales" component={BlogDescripcionIndexComponent} />
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/anuncios-generales/crear" component={BlogDescripcionCreateComponent} />
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/anuncios-generales/:id/editar" component={BlogDescripcionEditComponent} />
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/categoria-de-anuncios" component={CategoriasBlogsIndexComponent} />
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/categoria-de-anuncios/crear" component={CategoriasBlogsCreateComponent} />
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/categoria-de-anuncios/:id/editar" component={CategoriasBlogsEditComponent} />
                                                <GuardedRoute exact path="/comunicacion/documentos/documentos-generales" component={BlogDescripcionIndexComponent} />
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/blog-de-noticias" component={BlogsCondominioComponent} />
                                                <GuardedRoute exact path="/comunicacion/anuncios-mensajes/blog-de-noticias/:id" component={BlogsListCondominioComponent} />


                                                <GuardedRoute exact path="/ajustes/ajustes/ajustes-unidades" component={AjustesHabitacionesIndexComponent} />

                                                <GuardedRoute exact path="/ajustes/ajustes/tipos-vivienda" component={TiposViviendaIndexComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/tipos-vivienda/crear" component={TiposViviendaCreateComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/tipos-vivienda/:id/editar" component={TiposViviendaEditComponent} />

                                                <GuardedRoute exact path="/ajustes/ajustes/departamentos" component={DepartamentosIndexComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/departamentos/crear" component={DepartamentosCreateComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/departamentos/:id/editar" component={DepartamentosEditComponent} />
                                                
                                                <GuardedRoute exact path="/ajustes/ajustes/administradores-y-guardias" component={UsuariosIndexComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/administradores-y-guardias/:id/editar" component={UsuariosEditComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/administradores-y-guardias/crear" component={UsuariosCreateComponent} />

                                                <GuardedRoute exact path="/ajustes/ajustes/guia-telefonica" component={TelefonosIndexComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/guia-telefonica/crear" component={TelefonosCreateComponent} />
                                                <GuardedRoute exact path="/ajustes/ajustes/guia-telefonica/:id/editar" component={TelefonosEditComponent} />

                                                <GuardedRoute exact path="/reportes/reportes-de-ingresos/:current" component={ReporteIngresosComponent} />
                                                <GuardedRoute exact path="/finanzas/ingresos/:current" component={ConfiguracionIngresosComponent} />

                                                <Route component={WorkingPage} />
                                              </Switch>
                                            


        { (currentUser) ?
        <RightSideBarNavComponent />
                    : null
                }

                
        </div>
        <div className="content-overlay"></div>
        </div>
        </div>

        {/*End MainDiv*/}
        </div>

        { (currentUser) ?
        <ThemeCutomizerSideNavComponent />
        : null
        }

    { (false) ?
        <FooterComponent />
        : null
    }
    

        { /*<AuthVerify logOut={this.logOut}/> */ }
      </div>
    );
  }
}

export default App;
