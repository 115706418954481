import React, { useEffect, useState } from "react";
import 'moment/locale/es'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { useForm } from 'react-hook-form';
import LoadingFormSteps from '../components/loading-form-steps.component';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import AuthService from "../services/auth.service";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import { Scrollbar } from "react-scrollbars-custom";


export const GET_DATA = gql`
query($condominioId: Int!) {
  viewer {
    allUsuarios(filters: {condominioId : $condominioId}) {
      edges {
        node {
          id
          username
          nombre
          paterno
          materno
        }
      }
    }
    allPaises {
      edges {
        node {
          id
          nombre
        }
      }
    }
    allZonasHorarias{
      edges{
        node{
          id
          nombre
        }
      }
    }
    allTiposVivienda{
      edges{
        node{
          id
          nombre
        }
      }
    }
    allRoles{
      edges{
        node{
          id
          name
        }
        
      }
    }
  }
}
`;

const SINGLE_UPLOAD_MUTATION = gql`
mutation uploadFileImportMutation($file: Upload!, $modelo: String!) {
  uploadFileImport(file: $file, model: $modelo) {
    success
    fileName
  }
}
`;

function ImportComponent(parametros) {
  let modelo = parametros.match.params.modelo;
  const history = useHistory();
  const hiddenFileInput = React.useRef(null);
  const [goSteps, setGoSteps] = useState(0);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [msg, setMsg] = useState('');
  const [from, setIsFrom] = useState(null);
  const [archivos, setArchivos] = useState([]);
  const [imageChange, setImageChange] = useState(false);
  const [fileName, setFilename] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState(null);
  const [cols, setCols] = useState(null);
  const [errorTypeFile, setErrorTypeFile] = useState(false);  
  

  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [usuarioId, setCurrentUserID] = useState(currentUser.id);
  const [condominioId, setCondominioId] = useState(currentUser.condominioId ? currentUser.condominioId : 0);

  const { loading: contactoLoading, error: contactoError, data: contactoData } = useQuery(GET_DATA,
    {
      variables: { condominioId: condominioId }
    },
     {
    onCompleted(data) {
      
    }
  });


  const [uploadRequest, { data: DataUpload, loading: LoadingUpload, error: ErrorUpload }] = useMutation(
    SINGLE_UPLOAD_MUTATION, {
    onCompleted(data) {
      const fileName2 = `/app-assets/uploads/${data.uploadFileImport.fileName}`;
      const nombreArchivo = data.uploadFileImport.fileName;
      setFilename(nombreArchivo);
      if(from){
      setImage(fileName2);
      }else{
        const obj = {'nombre':nombreArchivo, 'url':fileName2};
        setArchivos([...archivos, obj]);
      }
      
      history.push({
        pathname: `/catalogos/${modelo}`,
        state: {
          response: 'succes'
        }
      });

    }
  }
  );


  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const renderFile = (fileObj) => {
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){           
      }
      else{
        //
        setCols(resp.cols);
        setRows(resp.rows);
        setDataLoaded(true);
      }
    });
}

  

  function setPreview(filessss, isFrom) {
    let archivoNombre = filessss.name;
    setErrorTypeFile(false);
    setDataLoaded(false);
    if(archivoNombre.slice(archivoNombre.lastIndexOf('.')+1) === "xlsx"){
      setIsFrom(isFrom);
      setFile(filessss);
      renderFile(filessss)   
    }    
    else{
      setErrorTypeFile(true);      
    }

  }

  const uploadFile = async () => {
    setMsg('');
    
    if (!file) return;
    try {
      const res = await uploadRequest({
        variables: { file, modelo }
        //,refetchQueries: [{ query: GET_PHOTOS_QUERY }],
      });
      if (res.data) {
        setMsg('File upload!');
        setImageChange(true);
        //setFile(null);
        setTimeout(() => setMsg(''), 300);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    
  }, []);

  //if (loading) return 'Loading...';
  if (contactoError) return `Error! ${contactoError.message}`;

  return (
    <div className="section">
      <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
      <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
        <div className="container">
          <div className="section section-form-wizard">
            <div className="row">
              <div className="col s12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-header">
                      <h4 className="card-title">Importar</h4>
                    </div>

                    <form>
                      <ul className="stepper" id="nonLinearStepper">

                        <li className={`step ${goSteps === 0 ? 'active' : null}`}>
                          <div onClick={() => setGoSteps(0)} className="step-title"></div>
                          <div className="step-content">

                          {contactoLoading ?
                              <LoadingFormSteps />
                              :
                              <>
                            <div className="row">
                            <div className="col m12 s12">
                                <p>Imágenes / Archivos (<small>Se permiten archivos de hasta 8MB</small>) </p>

                                <div className="app-file-content">
                                <div className="display-flex">
                                  <div className="media-body">
                                    <div className="general-action-btn">
                                    <a onClick={handleClick} className="btn btn-block waves-effect waves-light pulse">
                                          <i className="material-icons left">add</i>Añadir Archivo
                                        </a>
                                      <input ref={hiddenFileInput}
                                              style={{display:'none'}} 
                                        className='btn indigo mr-2'
                                        type='file'
                                        onChange={(e) => setPreview(e.target.files[0], false)}
                                      />

                                    </div>
                                    <small>Allowed XLSX. Max size of 800kB </small>
                                    <p><small>{fileName}</small></p>
                                    
                                    {errorTypeFile &&
                                    <small className="errorTxt2">
                                      <div id="cdomicilio-error" className="error">
                                      Tipo de archivo no permitido.
                                      </div>
                                    </small>
                                    }

                                    <p>{LoadingUpload && 'Uploading...'}</p>
                                    <p>{ErrorUpload?.message}</p>
                                  </div>
                                </div>
                                </div>
                              </div>
                            <div className="input-field col m12 s12">
                            <Scrollbar style={{ width: 100 + '%', height: 200 + 'px' }}>
                            {dataLoaded &&
                            <OutTable data={rows} columns={cols} tableClassName="display nowrap" tableHeaderRowclassName="heading" />
                            }
                            </Scrollbar>
                            </div>
                             
                            </div>
                            <div className="step-actions">
                              <div className="row">

                                <div className="col m6 s12 mb-3">
                                  <a className="btn btn-light" target="_blank"
                                  href="/app-assets/templates/proveedoresPlantilla.xlsx"
                                  download>
                                    <i className="material-icons left">file_download</i>
                                    Plantilla
                                  </a>
                                </div>
                                
                                <div className="col m6 s12 mb-3">
                                  {!LoadingUpload ?
                                    <a onClick={() => uploadFile()} className="waves dark btn btn-primary">
                                      <i className="material-icons left">upload_file</i>
                                      Importar
                                    </a>
                                    :
                                    <button type="submit" className=" btn-customizer waves dark btn btn-primary" onClick={() => setGoSteps(0)}>
                                      <i className="material-icons left">sync</i>
                                      Importando
                                    </button>
                                  }

                                </div>
                              </div>
                            </div>
                            </>
                          }


                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ImportComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default ImportComponent;
