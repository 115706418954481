import React from 'react';
import {
	BrowserRouter as Router,
	Link,
	useLocation
  } from "react-router-dom";

const FooterComponent = () => {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    const showModeratorBoard = true;
    const showAdminBoard = true;

    return (
           
      <footer
      className="page-footer footer footer-fixed footer-dark gradient-45deg-indigo-purple gradient-shadow navbar-border navbar-shadow">
      <div className="footer-copyright">
          <div className="container"><span>&copy; {new Date().getFullYear()} <a
                      href="http://themeforest.net/user/pixinvent/portfolio?ref=pixinvent"
                      target="_blank">CREASIS</a> Todos los derechos reservados.</span><span
                  className="right hide-on-small-only">Diseñado y Desarrollado por <a
                      href="https://pixinvent.com/">CREASIS</a></span></div>
      </div>
  </footer>
           
        )
    }


export default FooterComponent
